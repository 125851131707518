import React, { Component, Fragment } from 'react'

import styled from 'styled-components'

import { ReactComponent as MadvoidLogo } from 'assets/logos/madvoid-logo-sm.svg'

import { ScrollFix } from 'components/ux/ScrollFix'
import { Flex, Box, Link } from 'components/ui'

import { TopNav } from './TopNav'
import { BottomNav } from './BottomNav'

const HeaderWrapper = styled.div`
  position: relative;
  height: 75px;
  color: 'white';
  z-index: 1;

  @media (max-width: 768px) {
    display: none;
  }
`

const SvgIcon = styled.div`
  display: inline-flex;
  align-self: center;
  margin-right: 8px;

  @keyframes colorCycle {
    0% {
      filter: hue-rotate(245deg);
    }
    50% {
      filter: hue-rotate(360deg);
    }
    100% {
      filter: hue-rotate(245deg);
    }
  }

  svg {
    height: 1em;
    width: 1em;
    top: 0.125em;
    position: relative;
    animation: colorCycle 30s infinite linear;

    > g > use {
      fill: hsl(291.1, 100%, 50%);
    }
  }
`

const InlineHeader = Flex.extend`
  background-color: #000;
  color: #fff;
  padding: 1.5rem 0;

  &.relative {
    position: relative;
    transform: translateY(0);
    transition: all 0;
  }

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transition: all 0.2s ease-in-out;
    /* transform: translateY(0); */
  }

  &.pin {
    transform: translateY(0);
  }

  &.unpin {
    transform: translateY(-100%);
  }
`

export class Header extends Component {
  render() {
    return (
      <ScrollFix>
        {className => (
          <Fragment>
            <HeaderWrapper>
              <InlineHeader is="header" className={className}>
                <Box px={2}>
                  <Link px={2} to="/" name="madvoid">
                    <SvgIcon>
                      <MadvoidLogo />
                    </SvgIcon>
                    MADVOID
                  </Link>
                </Box>
                <TopNav />
              </InlineHeader>
            </HeaderWrapper>
            <BottomNav className={className} />
          </Fragment>
        )}
      </ScrollFix>
    )
  }
}

export default Header
