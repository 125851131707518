import { tween } from 'popmotion'
import { SVGDrawer, MultiSVGDrawer } from 'utils/SVGDrawer'

export default class Animation {
  constructor(props = { duration: 200 }) {
    this.props = props
  }

  init(lines) {
    this.drawers = lines.map(line => new SVGDrawer(line))
    this.allLines = new MultiSVGDrawer(this.drawers)

    this.allLines.set('opacity', 0)
    this.allLines.draw([0, 0])
  }

  show() {
    if (this.hideTween) this.hideTween.stop()
    this.allLines.set('opacity', 1)

    this.showTween = tween({
      from: this.drawers[0].draw,
      to: [20, 100],
      duration: this.props.duration,
    }).start(this.allLines.draw)
  }

  hide() {
    if (this.showTween) this.showTween.stop()
    const duration = (this.showTween && this.showTween.getElapsed()) || this.props.duration

    this.hideTween = tween({
      from: this.drawers[0].draw,
      to: [0, 0],
      duration,
    }).start({
      update: this.allLines.draw,
      complete: () => this.allLines.set('opacity', 0),
    })
  }
}
