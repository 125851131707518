import sys from 'system-components'
import Image from 'gatsby-image'

export const Img = sys(
  {
    is: Image,
  },
  'space',
)
Img.displayName = 'Img'
