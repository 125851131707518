import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import 'typeface-open-sans'

import { injectGlobal } from 'styled-components'
import { Provider as Theme } from 'rebass'

import madvoidTheme from 'styles/theme'
import { Flex } from 'components/ui'
import { Header } from 'components/layout/Header'
import { Footer } from 'components/layout/Footer'

import { siteDescription, siteKeywords } from '../../config/site'

// Reset
injectGlobal`
  * {
    border: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: normal;
    margin: 0;
    outline: 0;
    padding: 0;
    text-decoration: none;
    text-rendering: optimizeLegibility;
    list-style: none;
  }

  html, body {
    min-height: 100vh;
    min-width: 100%;

    font-size: 16px;
    scroll-behavior: smooth;
  }

  a {
    color: currentColor;
    text-decoration: none;
  }

  textarea {
    resize: none;
  }

  b {
    font-weight: bold;
  }
`

injectGlobal`
  @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');
  @import url('https://fonts.googleapis.com/css?family=Lato:700');

  html {
    color: #000;
    line-height: 1.5;
    /* font-family: 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'; */
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    font-weight: 300;
    font-size: 1rem !important;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
`

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteMetaQuery {
        site {
          siteMetadata {
            description
            keywords
          }
        }
      }
    `}
    render={data => (
      <Theme theme={madvoidTheme}>
        <Helmet defaultTitle="Madvoid - Brings your digital product ideas to life" titleTemplate="%s | Madvoid">
          <meta name="description" content={data.site.siteMetadata.description} />
          <meta name="keywords" content={data.site.siteMetadata.keywords} />
          <meta name="twitter:site" content="@madvoid" />
          <meta name="og:type" content="website" />
          <meta name="og:site_name" content="Madvoid" />
          <html lang="en" />
        </Helmet>
        <Flex flexDirection="column">
          <Header />
          <main>{children}</main>
          <Footer />
        </Flex>
      </Theme>
    )}
  />
)

// Layout.propTypes = {
//   children: PropTypes.element.isRequired,
// }

export default Layout
