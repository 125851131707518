import system from 'system-components'
// import { themeGet } from 'styled-system'
// FIXME: See variant (https://jxnblk.com/styled-system/api#variant)

export const Button = system(
  {
    is: 'button',
    fontSize: 2,
    fontWeight: 'bold',
    lineHeight: 16 / 14,
    m: 0,
    px: 4,
    py: 3,
    color: 'text.onPrimary',
    // background-color: #0a84ff;
    background: '#306efe linear-gradient(120deg,#30cde2 0,#306efe 100%) no-repeat',
    borderRadius: 2,
    border: 0,
  },
  () => ({
    display: 'inline-block',
    cursor: 'pointer',
    fontFamily: 'inherit',
    WebkitFontSmoothing: 'antialiased',
    verticalAlign: 'middle',
    textAlign: 'center',
    textDecoration: 'none',
    textShadow: 'none',
    appearance: 'none',
    // '&:hover': {
    //   boxShadow: `inset 0 0 0 999px ${themeGet('colors.darken.0')(props)}`,
    // },
    // '&:focus': {
    //   outline: 0,
    //   boxShadow: `0 0 0 2px ${themeGet('colors.blue')(props)}`,
    // },
    // '&:active': {
    //   backgroundColor: themeGet('colors.blue.6')(props),
    //   boxShadow: `inset 0 0 8px ${themeGet('colors.darken.1')(props)}`,
    // },
    '&:disabled': {
      opacity: 1 / 4,
    },
  }),
)

Button.displayName = 'Button'

export default Button
