import React from 'react'
import PropTypes from 'prop-types'

export class ScrollWatch extends React.Component {
  static propTypes = { children: PropTypes.func.isRequired }

  state = {
    x: 0,
    y: 0,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    this.setState({
      x: window.scrollX,
      y: window.scrollY,
    })
  }

  render() {
    const { x, y } = this.state
    return this.props.children(x, y)
  }
}

export default ScrollWatch
