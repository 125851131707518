import React from 'react'
import { Text } from 'rebass'
import { Flex, Box } from '../ui'

export const Footer = () => (
  <Flex is="footer" p={3} flexWrap="wrap" bg="primaryDark" color="text.onPrimary">
    <Box width={[1]}>
      <Text textAlign="center">© 2018 Madvoid</Text>
    </Box>
    <Box width={[1]} color="text.onPrimaryMedium">
      <Text textAlign="center">Madvoid is a registered trademark of Madvoid, SASU.</Text>
    </Box>
  </Flex>
)

export default Footer
