import system from 'system-components'
import { Flex } from 'grid-styled'

import { AnimatedLink } from './AnimatedLink'
import { Link } from './Link'

export const HeaderNav = system(
  {
    is: Flex.withComponent('nav'),
    textAlign: 'right',
  },
  'space',
)
HeaderNav.displayName = 'HeaderNav'

HeaderNav.Link = Link

// HeaderNav.AnimatedLink = system({
//   is: AnimatedLink,
//   // display: 'block',
//   // py: 1,
// })
// HeaderNav.AnimatedLink.displayName = 'HeaderNav.AnimatedLink'
HeaderNav.AnimatedLink = AnimatedLink

export default HeaderNav
