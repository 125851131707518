import sys from 'system-components'
import { Flex } from 'grid-styled'

export const Section = sys(
  {
    is: Flex.withComponent('section'),
    mb: 1,
    // p: [3, 4],
    flexDirection: 'column',
    align: 'center',
    justify: 'center',
    // minHeight: '80vh',
    position: 'relative',
    color: 'text.onPrimary',
    // color: 'white',
    // bg: 'dark',
  },
  'color',
  'minHeight',
  'backgroundSize',
  'backgroundPosition',
  'backgroundImage',
  'fontSize',
  'borders',
)

Section.displayName = 'Section'

export default Section
