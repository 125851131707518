import system from 'system-components'
import { Button } from 'components/ui'

export const QuickMenuButton = system({
  is: Button,
  fontSize: 1,
  position: 'fixed',
  bottom: '20px',
  px: 3,
  py: '12px',
  lineHeight: 1,
  borderRadius: 100,
})

QuickMenuButton.displayName = 'QuickMenuButton'

export default QuickMenuButton
