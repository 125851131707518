import React, { Fragment } from 'react'

import { Fixed } from 'rebass'
import ScrollLock from 'react-scrolllock'

// import { TransitionMotion, spring, presets } from 'react-motion'

import { QuickMenuButton, FooterNav } from 'components/ui'

const QuickMenu = QuickMenuButton.extend`
  transition: all 0.2s ease-in-out;
  transform: translateX(-50%) translateY(0);
  left: 50%;
  z-index: 100;

  @media (min-width: 769px) {
    display: none;
  }

  &.pin {
    transform: translateX(-50%) translateY(0);
  }
  &.unpin {
    transform: translateX(-50%) translateY(200%);
  }
`

export class BottomNav extends React.Component {
  state = {
    expanded: false,
  }

  toggleExpand = () => {
    this.setState({
      expanded: !this.state.expanded,
    })
  }

  render() {
    return (
      <>
        {this.state.expanded && (
          <>
            <ScrollLock />
            <Fixed top={0} bottom={0} left={0} right={0} onClick={this.toggleExpand} bg="gray90.a30" key="quickmenu-overlay" zIndex="99" />
          </>
        )}
        <QuickMenu className={this.props.className} onClick={this.toggleExpand} key="quickmenu-button">
          ☰ Quick Menu
        </QuickMenu>
        <FooterNav expanded={this.state.expanded}>
          <FooterNav.Link to="/" name="madvoid">
            Madvoid
          </FooterNav.Link>
          <FooterNav.Link to="/work" name="work">
            Work
          </FooterNav.Link>
          <FooterNav.Header onClick={this.toggleExpand}>✕ Quick Menu</FooterNav.Header>
        </FooterNav>
      </>
    )
  }
  // this.state.expanded && (
  //   <Fixed top={0} bottom={0} left={0} right={0} onClick={this.toggleExpand} bg="gray90.a30" key="quickmenu-overlay" zIndex="99" />
  // ),
  // <TransitionMotion
  //   defaultStyles={this.getDefaultStyles()}
  //   styles={this.getStyles()}
  //   willEnter={this.willEnter}
  //   willLeave={this.willLeave}
  //   key="quickmenu-transition"
  // >
  //   {styles => (
  //     <Fragment>
  //       {styles.map(({ key, style }) => (
  //         <FooterNav is="footer" key={key} y={style.y}>
  //           <FooterNav.Header onClick={this.toggleExpand}>Close</FooterNav.Header>
  //           <FooterNav.Link to="/" name="madvoid">
  //             Madvoid
  //           </FooterNav.Link>
  //           <FooterNav.Link to="/work" name="work">
  //             Work
  //           </FooterNav.Link>
  //           <ScrollLock />
  //         </FooterNav>
  //       ))}
  //     </Fragment>
  //   )}
  // </TransitionMotion>,
  // ]
  // }
}

export default BottomNav
