import styled from 'styled-components'
import { Heading } from './Heading'

export const Subhead = styled(Heading)`
  position: relative;
  text-transform: uppercase;

  &::before {
    content: '';
    position: absolute;
    top: -2px;
    bottom: -2px;
    width: 2px;
    left: -12px;
    background-color: white;
  }
`

Subhead.displayName = 'Subhead'

Subhead.defaultProps = {
  is: 'h3',
  fontSize: '1em',
}

export default Subhead
