import { Link as StaticLink } from 'gatsby'
import sys from 'system-components'

export const Link = sys(
  {
    is: StaticLink,
  },
  'space',
)
Link.displayName = 'Link'

export default Link
