import system from 'system-components'

export const Box = system(
  {},
  {
    boxSizing: 'border-box',
  },
  'width',
  'maxWidth',
  'space',
  'fontSize',
  'color',
  'flex',
  'order',
  'alignSelf',
)
Box.displayName = 'Box'

export default Box
