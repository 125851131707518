module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "/portfolio"

  siteTitle: 'Madvoid - Brings your digital product ideas to life', // Navigation and Site Title
  siteTitleAlt: 'Madvoid', // Alternative Site title for SEO
  siteUrl: 'https://wip-112358.madvoid.com/', // Domain of your site.

  siteLanguage: 'en', // Language Tag on <html> element
  // siteLogo: '/logos/logo-1024.png', // Used for SEO and manifest

  siteDescription:
    'Madvoid is a team of expert developers dedicated to simple, clear, usable and blazing fast web and mobile app. We bring your digital product ideas to life, and we do it well.',
  siteKeywords:
    'web, web app, mobile, mobile app, development, developers, consulting, paris, france, freelance, Ruby, Ruby on Rails, Rails, Elixir, Phoenix, CSS, HTML, jQuery, React, GraphQL, responsive, fluid, micro-interactions, animations, progressive web app, SEO, social media, chatbot, Facebook, Twitter, Docker, simple, fast loading, simplify, simplicity, usability, user experience, customer experience, expert, clean, clean code, reliable, honest, professional, trust, improve, best practices, performance, award, madvoid',

  // siteFBAppID: '123456789', // Facebook App ID
  userTwitter: '@madvoid', // Twitter Username
  ogSiteName: 'madvoid', // Facebook Site Name
  ogLanguage: 'en', // Facebook Language

  // googleAnalyticsID: 'UA-12345689-1',

  // Manifest and Progress color
  // themeColor: '#3498DB',
  // themeColor: '#9622dd',
  themeColor: '#670d61',
  backgroundColor: '#2b2e3c',

  // Settings for typography.js
  // headerFontFamily: 'Open Sans',
  // bodyFontFamily: 'Space Mono',
  // baseFontSize: '16px',
}
