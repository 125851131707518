import { style } from 'styled-system'
import system from 'system-components'
import { Flex } from 'rebass'

import { Link } from './Link'

// const transformStyles = style({
//   prop: 'y',
//   cssProperty: 'transform',
//   numberToPx: false,
//   key: 'transform',
//   getter: n => `translateY(${n}%)`,
// })

export const FooterNav = system(
  {
    is: Flex.withComponent('nav'),
    blacklist: ['expanded'],
    flexDirection: 'column',

    shadow: 2,
    position: 'fixed',
    bottom: '20px',
    zIndex: 100,

    width: '90vw',

    borderRadius: 2,
    boxShadow: 1,

    fontSize: 1,
    lineHeight: 1,
    textAlign: 'center',
  },
  {
    opacity: 0,
    visibility: 'hidden',
    backgroundColor: '#fff',
    left: '50%',
    transform: 'translateX(-50%)',
    transition: 'all 0.2s ease-in-out',
  },
  props =>
    props.expanded && {
      visibility: 'visible',
      opacity: 1,
      // minWidth: '90vw',
      // maxWidth: '90vw',
      color: '#000',
      // transform: 'translateX(-50%) scale(1)',
    },
  // transformStyles,
)
FooterNav.displayName = 'FooterNav'

FooterNav.Header = system(
  {
    // is: 'header',
    // borderBottom: 1,
    // borderColor: 'gray.30',
    px: 3,
    py: '12px',
    fontWeight: 'bold',
    // py: '12px',
  },
  {
    cursor: 'pointer',
  },
)
FooterNav.Header.displayName = 'FooterNav.Header'

FooterNav.Link = system({
  is: Link,
  display: 'block',
  borderBottom: 1,
  borderColor: 'gray.30',
  // py: 2,
  px: 3,
  py: '12px',
})
FooterNav.Link.displayName = 'FooterNav.Link'

export default FooterNav
