import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { ScrollWatch } from './ScrollWatch'

class DeltaScrollY extends Component {
	static propTypes = {
		y: PropTypes.number.isRequired,
		thresholdUp: PropTypes.number,
		thresholdDown: PropTypes.number,
		children: PropTypes.func.isRequired
	}

	constructor(props) {
		super(props)
		// Class variables.
		this.delta = 0
		this.direction = null
		this.state = {
			direction: null
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.y !== this.props.y) {
			const delta = nextProps.y - this.props.y

			// Scrolling down
			if (delta > 0) {
				if (this.direction === 'down') {
					this.delta += delta
				} else {
					this.direction = 'down'
					this.delta = delta
				}
			} else if (this.direction === 'up') {
				this.delta += delta
			} else {
				this.direction = 'up'
				this.delta = delta
			}

			if (
				(this.direction === 'up' && -this.delta > this.props.thresholdUp) ||
				(this.direction === 'down' && this.delta > this.props.thresholdDown)
			) {
				this.setState({ direction: this.direction })
			}
		}
	}

	render() {
		const from = this.props.y - this.delta
		return this.props.children(this.state.direction, from)
	}
}

export class ScrollFix extends Component {
	computeState = (dir, y, fromY) => {
		if (y < 72 && dir === 'down') {
			return 'relative'
		} else if (fromY < 72 && dir === 'down') {
			return 'relative unpin'
		} else if (dir === 'down') {
			return 'fixed unpin'
		} else if (dir === 'up') {
			return 'fixed pin'
		}
	}

	render() {
		return (
			<ScrollWatch>
				{(x, y) => (
					<DeltaScrollY y={y} thresholdUp={40} thresholdDown={0}>
						{(dir, fromY) => this.props.children(this.computeState(dir, y, fromY))}
					</DeltaScrollY>
				)}
			</ScrollWatch>
		)
	}
}

export default ScrollFix
