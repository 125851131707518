import React from 'react'
import { HeaderNav as BaseHeaderNav } from 'components/ui'

const HeaderNav = BaseHeaderNav.extend`
  a {
    position: relative;
    min-width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 8px;
    padding: 0 8px;
  }
`

export const TopNav = () => (
  <HeaderNav ml="auto" px={2}>
    <HeaderNav.AnimatedLink px={2} to="/work" name="work">
      Our Work
    </HeaderNav.AnimatedLink>
    <HeaderNav.AnimatedLink px={2} to="/hire-us" name="hire us">
      Hire Us
    </HeaderNav.AnimatedLink>
  </HeaderNav>
)

export default TopNav
