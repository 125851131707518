import styler from 'stylefire'

function dist(x1, x2, y1, y2){
  const dist = Math.sqrt((x2 -= x1) * x2 + (y2 -= y1) * y2)
  return dist
}

export class SVGDrawer {
  constructor(node) {
    // this.node = node
    this.styler = styler(node)
    window.node = node
    const isLine = node instanceof SVGLineElement
    this.totalLength = isLine
      ? dist(node.x1.baseVal.value, node.x2.baseVal.value, node.y1.baseVal.value, node.y2.baseVal.value)
      : node.getTotalLength()
  }

  get draw() {
    return [this.start, this.end]
  }

  set draw([start, end]) {
    const length = (end - start) / 100 * this.totalLength
    this.styler.set('stroke-dashoffset', -start / 100 * this.totalLength)
    this.styler.set('stroke-dasharray', [length, this.totalLength])
  }

  get start() {
    return -this.styler.get('stroke-dashoffset') / this.totalLength * 100
  }

  get end() {
    const length = this.styler.get('stroke-dasharray')[0]
    return length / this.totalLength * 100 + this.start
  }
}

export class MultiSVGDrawer {
  constructor(drawers) {
    this.drawers = drawers
  }

  draw = ([start, end]) => {
    this.drawers.forEach(drawer => {
      drawer.draw = [start, end]
    })
  }

  // Delegate set(attr, value) to styler
  set = (attr, value) => {
    this.drawers.forEach(drawer => {
      drawer.styler.set(attr, value)
    })
  }
}
