import React from 'react'
import styled from 'styled-components'
import { Link } from 'components/ui'

import Animation from './Animation'

export class AnimatedLink extends React.Component {
  static defaultProps = {
    size: 10,
    strokeWidth: 1,
    offset: 5,
    stroke: 'currentColor',
    duration: 200,
  }

  constructor(props) {
    super(props)
    this.topLine = React.createRef()
    this.bottomLine = React.createRef()
    this.animation = new Animation({ duration: props.duration })
  }

  componentDidMount() {
    const lines = [this.topLine.current, this.bottomLine.current]
    this.animation.init(lines)
  }

  handleEnter = () => {
    this.animation.show()
  }

  handleExit = () => {
    this.animation.hide()
  }

  render() {
    const { to, name, children, size, strokeWidth, offset, stroke } = this.props
    const svgProps = { size, strokeWidth, offset, stroke }

    return (
      <Link to={to} name={name} onMouseEnter={this.handleEnter} onMouseLeave={this.handleExit}>
        <BorderSvg line={this.topLine} position="top" {...svgProps} />
        {children}
        <BorderSvg line={this.bottomLine} position="bottom" {...svgProps} />
      </Link>
    )
  }
}

const BorderSvg = ({ size, strokeWidth, position, stroke, ...props }) => {
  const width = size + strokeWidth
  const height = size * 1.5 + strokeWidth
  const halfStroke = strokeWidth / 2.0
  const d =
    position === 'top'
      ? `M${halfStroke},${height - halfStroke} ${halfStroke},${halfStroke} ${width - halfStroke},${halfStroke} `
      : `M${width - halfStroke},${halfStroke} ${width - halfStroke},${height - halfStroke} ${halfStroke},${height - halfStroke} `

  const svgProps = {
    className: position,
    width,
    height,
    offset: props.offset,
  }

  const lineProps = { stroke, strokeWidth, d }

  return (
    <Svg {...svgProps}>
      <defs />
      <Path innerRef={props.line} {...lineProps} />
    </Svg>
  )
}

const Svg = styled.svg.attrs({
  version: '1.1',
  viewBox: props => `0 0 ${props.width} ${props.height}`,
})`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  margin: auto;
  position: absolute;

  &.top {
    top: ${props => -props.offset || 0}px;
    left: ${props => -props.offset || 0}px;
  }

  &.bottom {
    bottom: ${props => -props.offset || 0}px;
    right: ${props => -props.offset || 0}px;
  }
`

const Path = styled.path`
  fill: none;
  stroke: ${props => props.stroke};
  stroke-width: ${props => props.strokeWidth};
  stroke-linecap: round;
  stroke-linejoin: round;
`

export default AnimatedLink
