// breakpoint values
// any array length works with styled-system
export const breakpoints = [
  32, // 512px   - sm
  48, // 768px   - m
  64, // 1024px  - l
  80, // 1280px  - xl
  100, // 1600px - xxl
].map(n => `${n}em`)

// space is used for margin and padding scales
// it's recommended to use powers of two to ensure alignment
// when used in nested elements
// numbers are converted to px
export const space = [
  0,
  4,
  8,
  16, // +24?
  32,
  64,
  128,
  256,
  512,
]

// Theme dark
// Bgs
// #fff (body)
// #000 (top bar)
// #141414 (nav)
// #1A1A1A (section dark) #101010 (darker) (text: #fff, links: #6bf)
// #fafafa (section light) (title: #111, text: #333)
// #f2f2f2 (footer light)
// #222 or #101010(footer dark) (text: #ccc)
// Margin between: 50px
// Gradients:
// linear-gradient(to right, #00fcd5, #00c6ff)
// linear-gradient(to right, #00c6ff, #a343da)


const colors = {
  primary: '#222',
  primaryLight: '#484848',
  primaryDark: '#000000',
  text: {
    onPrimary: '#fff',
    onPrimaryMedium: 'rgba(255, 255, 255, 0.60)',
    onPrimaryDisabled: 'rgba(255, 255, 255, 0.38)',
  },
  // blue: '#07c',
  // nested objects work as well
  dark: {
    blue: '#058',
  },
  white: {
    a20: 'rgba(255, 255, 255, 0.20)',
    a60: 'rgba(255, 255, 255, 0.60)',
    a90: 'rgba(255, 255, 255, 0.80)',
    a100: '#fff',
  },
  gray: {
    10: '#f9f9fa',
    20: '#ededf0',
    30: '#d7d7db',
    40: '#b1b1b3',
    50: '#737373',
    60: '#4a4a4f',
    70: '#38383d',
    80: '#2a2a2e',
    90: '#0c0c0d',
  },
  gray90: {
    a05: 'rgba(12, 12, 13, 0.05)',
    a10: 'rgba(12, 12, 13, 0.1)',
    a20: 'rgba(12, 12, 13, 0.2)',
    a30: 'rgba(12, 12, 13, 0.3)',
    a40: 'rgba(12, 12, 13, 0.4)',
    a50: 'rgba(12, 12, 13, 0.5)',
    a60: 'rgba(12, 12, 13, 0.6)',
    a70: 'rgba(12, 12, 13, 0.7)',
    a80: 'rgba(12, 12, 13, 0.8)',
    a90: 'rgba(12, 12, 13, 0.9)',
  },
  black: {
    a05: 'rgba(0, 0, 0, 0.05)',
    a10: 'rgba(0, 0, 0, 0.1)',
    a20: 'rgba(0, 0, 0, 0.2)',
    a30: 'rgba(0, 0, 0, 0.3)',
    a40: 'rgba(0, 0, 0, 0.4)',
    a50: 'rgba(0, 0, 0, 0.5)',
    a60: 'rgba(0, 0, 0, 0.6)',
    a70: 'rgba(0, 0, 0, 0.7)',
    a80: 'rgba(0, 0, 0, 0.8)',
    a90: 'rgba(0, 0, 0, 0.9)',
  },
  // black: '#000',
  grey: {
    darkest: '#273238',
    darker: '#364349',
    dark: '#70818a',
    normal: '#9babb4',
    light: '#dae4e9',
    lighter: '#f3f7f9',
    lightest: '#fafcfc',
  },
  // white: '#fff',
  red: {
    darkest: '#420806',
    darker: '#6a1b19',
    dark: '#cc1f1a',
    normal: '#bf381a',
    light: '#ef5753',
    lighter: '#f9acaa',
    lightest: '#fcebea',
  },
  orange: {
    darkest: '#542605',
    darker: '#7f4012',
    dark: '#c1611f',
    normal: '#e07628',
    light: '#ffa31b',
    lighter: '#fcd9b6',
    lightest: '#fff5eb',
  },
  yellow: {
    darkest: '#453411',
    darker: '#684f1d',
    dark: '#f2d024',
    normal: '#e9af32',
    light: '#fff382',
    lighter: '#fff9c2',
    lightest: '#fcfbeb',
  },
  green: {
    darkest: '#032d19',
    darker: '#0b4228',
    dark: '#1f9d55',
    normal: '#38c172',
    light: '#51d88a',
    lighter: '#a2f5bf',
    lightest: '#e3fcec',
  },
  teal: {
    darkest: '#0d3331',
    darker: '#174e4b',
    dark: '#38a89d',
    normal: '#4dc0b5',
    light: '#64d5ca',
    lighter: '#a0f0ed',
    lightest: '#e8fffe',
  },
  blue: {
    darkest: '#0a224e',
    darker: '#103d60',
    dark: '#2779bd',
    normal: '#a0d8f1',
    light: '#6cb2eb',
    lighter: '#bcdefa',
    lightest: '#eff8ff',
  },
  indigo: {
    darkest: '#191e38',
    darker: '#2f365f',
    dark: '#5661b3',
    normal: '#6574cd',
    light: '#7886d7',
    lighter: '#b2b7ff',
    lightest: '#e6e8ff',
  },
  purple: {
    darkest: '#1f133f',
    darker: '#352465',
    dark: '#794acf',
    normal: '#9561e2',
    light: '#a779e9',
    lighter: '#d6bbfc',
    lightest: '#f3ebff',
  },
  pink: {
    darkest: '#45051e',
    darker: '#72173a',
    dark: '#eb5286',
    normal: '#f66d9b',
    light: '#fa7ea8',
    lighter: '#ffbbca',
    lightest: '#ffebef',
  },
  shadow: 'rgba(12, 12, 13, 0.1)',
}

// border-radius
const radii = [0, 2, 4, 8]

const borders = [0, '1px solid', '2px solid']

const shadows = ['none', `0 1px 4px ${colors.shadow}`, `0 2px 8px ${colors.shadow}`, `0 4px 16px ${colors.shadow}`]

export const fontSizes = [12, 14, 16, 20, 24, 32, 48, 64, 72, 96]

const fontWeights = {
  normal: 400,
  bold: 700,
}
// for any scale, either array or objects will work
const lineHeights = [1, 1.125, 1.25, 1.5]

const letterSpacings = {
  normal: 'normal',
  caps: '0.25em',
}

const fonts = {
  sans: '"Open Sans", system-ui, sans-serif',
}

export default {
  breakpoints,
  colors,
  space,
  fonts,
  fontSizes,
  lineHeights,
  fontWeights,
  letterSpacings,
  radii,
  borders,
  shadows,
}
